import React from "react"
import { ResponsiveLineCanvas } from "@nivo/line"

const ChartLine = ({ data }) => (
  <ResponsiveLineCanvas
    markers={[
      {
        axis: "y",
        value: 0,
        lineStyle: { stroke: "#b0413e", strokeWidth: 1 },
        legend: "y marker at 0",
        legendPosition: "bottom-left",
      },
    ]}
    data={data}
    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
    xScale={{
      type: "time",
      format: "%Y-%m-%d %H:%M:%S",
      precision: "second",
      min: 'auto',
      max: 'auto'
    }}
    // xFormat="time:%b %d %H:%M"
    xFormat="time:%H:%M"
    yScale={{
      type: "linear",
      min: "auto",
      max: "auto",
      stacked: false,
      reverse: false,
    }}
    yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: "bottom",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "time",
      // legendOffset: -12,
      legendOffset: 36,
      legendPosition: "middle",
      format: "%b %d",
      tickValues: "every day",
    }}
    axisLeft={{
      orient: "left",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "temp °C",
      legendOffset: -40,
      legendPosition: "middle",
    }}
    curve="monotoneX"
    enablePoints={true}
    enablePointsLabel={false}
    enableSlices={false}
    pointSize={2}
    // pointColor={{ theme: "background" }}
    // pointBorderWidth={1}
    // pointBorderColor={{ from: "serieColor" }}
    // pointLabelYOffset={-12}
    // pointLabel={({ x, y }) => y}
    useMesh={true}
    tooltip={({
      point: {
        data: { xFormatted, yFormatted },
      },
      serieColor,
    }) => (
      <div
        style={{
          background: "white",
          padding: "9px 12px",
          border: "1px solid rgba(0, 0, 0, .25)",
          boxShadow: "0 0 2px rgba(0, 0, 0, .15)",
        }}
      >
        {yFormatted} °C
        <small style={{
            marginLeft: '5px'
        }}>· {xFormatted}</small>
      </div>
    )}
    legends={[
      {
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: "left-to-right",
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: "circle",
        symbolBorderColor: "rgba(0, 0, 0, .5)",
        effects: [
          {
            on: "hover",
            style: {
              itemBackground: "rgba(0, 0, 0, .03)",
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
  />
)

export default ChartLine
