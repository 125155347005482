import React from "react"
import { Helmet } from "react-helmet"
import WeeklyChart from "../../components/iot-platform/weekly"

const WeeklyPage = () => (
  <>
    <Helmet>
      <title>Weekly</title>
      <meta name="robots" content="noindex" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
    </Helmet>
    <div style={{
        width: '100%',
        height: '500px'
    }}>
    <WeeklyChart />
    </div>
  </>
)

export default WeeklyPage
