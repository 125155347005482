import React, { useState, useEffect } from "react"
import format from "date-fns/format"
import startOfWeek from "date-fns/startOfWeek"
import endOfWeek from "date-fns/endOfWeek"
import Chart from "./Chart"

const WeeklyChart = () => {
  const SECS_IN_WEEK = 60 * 60 * 24 * 7
  const TODAY = ~~(Date.now() / 1000)

  const [data, setData] = useState(null)
  const [timeInWeek, setWeek] = useState(~~(Date.now() / 1000))
  const [isLoading, setIsLoading] = useState(true)

  const loadData = async () => {
    setIsLoading(true)

    // data --> { sensor, outside }
    const { outside, sensor } = await fetch(
      `${process.env.GATSBY_API_URL}/iot-platform/weekly?dt=${timeInWeek}`
    ).then(r => r.json())

    const data = [
      {
        id: "outside temp",
        data: outside
          .filter(i => !!i)
          .map(({ dt, temp }) => ({
            x: format(new Date(dt * 1000), "yyyy-MM-dd HH:mm:ss"),
            y: temp,
          })),
      },
      {
        id: "sensor temp",
        data: sensor
          .filter(i => i && i.temp)
          .map(({ dt, temp }) => ({
            x: format(new Date(dt * 1000), "yyyy-MM-dd HH:mm:ss"),
            y: temp,
          })),
      },
    ]

    setData(data)
    setIsLoading(false)
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeInWeek])

  return (
    <>
      <p>
        <button
          type="button"
          style={{
            margin: "5px"
          }}
          disabled={isLoading}
          onClick={() => setWeek(timeInWeek - SECS_IN_WEEK)}
        >
          {"< Previous week"}
        </button>
        <button
          style={{
            margin: "5px"
          }}
          type="button"
          onClick={() => setWeek(timeInWeek + SECS_IN_WEEK)}
          disabled={isLoading || timeInWeek + SECS_IN_WEEK > TODAY}
        >
          {"Next week >"}
        </button>
        <span style={{opacity: isLoading ? 1 : 0.25}}>⟳</span>
        <span style={{margin: "5px"}}>· · ·</span>
        {format(startOfWeek(new Date(timeInWeek * 1000)), "yyyy/MM/dd")} · {" "} 
        {format(endOfWeek(new Date(timeInWeek * 1000)), "yyyy/MM/dd")}
      </p>
      {data && <Chart data={data} />}
    </>
  )
}

export default WeeklyChart
